<template>
  <div>
    <div v-show="preData&&role" class="lineChart" id="echarts" ref="echarts">

    </div>
  </div>
</template>

<script>
let elementResizeDetectorMaker = require("element-resize-detector");

export default {
  name: "LineChart",
  data(){
    return{
      chart:null,
      defaultOption:{"title":{"text":"折线图"},"tooltip":{"trigger":"axis"},"legend":{"data":["setosa","versicolor","virginica"]},"grid":{"left":"3%","right":"4%","bottom":"3%","containLabel":true},"toolbox":{"feature":{"saveAsImage":{}}},"xAxis":{"type":"category","boundaryGap":false,"data":[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]},"yAxis":{"type":"value"},"series":[{"name":"setosa","type":"line","stack":"Total","data":[1.4,1.4,1.3,1.5,1.4,1.7,1.4,1.5,1.4,1.5,1.5,1.6,1.4,1.1,1.2,1.5,1.3,1.4,1.7,1.5,1.7,1.5,1,1.7,1.9,1.6,1.6,1.5,1.4,1.6,1.6,1.5,1.5,1.4,1.5,1.2,1.3,1.4,1.3,1.5,1.3,1.3,1.3,1.6,1.9,1.4,1.6,1.4,1.5,1.4]},{"name":"versicolor","type":"line","stack":"Total","data":[4.7,4.5,4.9,4,4.6,4.5,4.7,3.3,4.6,3.9,3.5,4.2,4,4.7,3.6,4.4,4.5,4.1,4.5,3.9,4.8,4,4.9,4.7,4.3,4.4,4.8,5,4.5,3.5,3.8,3.7,3.9,5.1,4.5,4.5,4.7,4.4,4.1,4,4.4,4.6,4,3.3,4.2,4.2,4.2,4.3,3,4.1]},{"name":"virginica","type":"line","stack":"Total","data":[6,5.1,5.9,5.6,5.8,6.6,4.5,6.3,5.8,6.1,5.1,5.3,5.5,5,5.1,5.3,5.5,6.7,6.9,5,5.7,4.9,6.7,4.9,5.7,6,4.8,4.9,5.6,5.8,6.1,6.4,5.6,5.1,5.6,6.1,5.6,5.5,4.8,5.4,5.6,5.1,5.1,5.9,5.7,5.2,5,5.2,5.4,5.1]}]},
      showData:{
        title: {
          text: '折线图'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          min: (value)=>{
            return value.min
          },
          max:(value)=>{
            return value.max
          }
        },
        series: [

        ]
      }
    }
  },
  props: {
    preData : {

    },
    role:{

    }
  },

  created() {

  },
  watch: {
    preData:{
      handler(){
        if(this.role){
          this.handleData()
          this.initData()
        }
      }
    },
    role:{
      handler() {
        if (this.preData) {
          this.handleData();
          this.initData()
        }
      }
    },
  },
  mounted() {
    var erd = elementResizeDetectorMaker();
    let that = this;
    erd.listenTo(document.getElementById("echarts"), function () {
      const dom = document.getElementById("echarts");
      dom.setAttribute('echarts_instance','')
      dom.style.width = dom.parentNode.parentNode.clientWidth + "px";
      dom.style.height = 600 + "px";
      if(that.role&&that.preData){
        that.handleData()
        that.initData()
      }
    })
  },
  methods:{
    handleData(){
      let maxLength=0;
      this.showData.title.text=this.role;
      //重置数据
      this.showData.legend.data=[];
      for (let i = 0; i < this.preData.length; i++) {
        this.showData.legend.data.push(this.preData[i].name)
        if(this.preData[i].data.length>maxLength){
          maxLength = this.preData[i].data.length;
        }
      }
      //重置数据
      this.showData.xAxis.data=[];
      for(let j=1;j<=maxLength;j++){
        this.showData.xAxis.data.push(j)
      }

      //重置数据
      this.showData.series=[];
      for (let i = 0; i < this.preData.length; i++) {
        let data = [];
        for(let j = 0;j<this.preData[i].data.length;j++){
          for(let key in this.preData[i].data[j]){
              if(key == this.role){
                data.push(this.preData[i].data[j][key])
              }
          }
        }
        let show = {
          name: this.preData[i].name,
          type: 'line',

          data: data
        }
        this.showData.series.push(show)
      }
      // console.log(JSON.stringify(this.showData))
    },
    initData(){
      let that = this;
      setTimeout(function () {
        if(that.chart){
          that.chart.dispose();
        }
        that.chart = that.$echarts.init(that.$refs.echarts);
        // 使用刚指定的配置项和数据显示图表。
        that.chart.setOption(that.showData,true);
      },100)
    }
  }

}
</script>

<style scoped>
.lineChart{
  width: 100%;
  height: 600px;
}
</style>